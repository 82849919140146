import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

import { ref, reactive } from 'vue'
import type { IProductData, IProductItem } from './types'
import DemoImage from '@/assets/images/product/Group 6436.png'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const router = useRouter()

const product = reactive<IProductData>({
  list: [
    { thumbnail: DemoImage, id: 1 },
    { thumbnail: DemoImage, id: 2 },
    { thumbnail: DemoImage, id: 3 },
    { thumbnail: DemoImage, id: 4 },
    { thumbnail: DemoImage, id: 5 },
    { thumbnail: DemoImage, id: 6 },
  ],
  total: 10,
  title: '产品'
})
const goCategory = (item: IProductItem) => {
  router.push(`/category?id=${item.id}`)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(product.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: index,
          onClick: ($event: any) => (goCategory(item))
        }, [
          _createElementVNode("img", {
            src: item.thumbnail,
            alt: ""
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4))
      }), 128))
    ])
  ]))
}
}

})